<template>
    <div class="block">
        <div v-if="data.Footer && windowWidth > 600" :style="data.Footer.Style" :class="data.Footer.Class">
            <div :class="data.Footer.ContainerClass">
                <div class="block mb-0 px-2" v-for="(item, n) in data.Footer.Data" :key="n" :style="item.Style">
                    <logo :data="item.Logo" />
                    <p v-if="item.Description">{{ item.Description }}</p>
                    <span class="font-bold text-xl">{{ item.Title }}</span>
                    <div class="block" v-if="item.Menu">
                        <menu-component :data="item.Menu" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="data.Copyright && windowWidth > 600" :style="data.Copyright.Style" :class="data.Copyright.Class">
            <div :class="data.Copyright.ContainerClass">
                <div class="my-auto" v-if="data.Copyright.Left">
                    <small>{{ data.Copyright.Left.Text }}</small>
                </div>
                <div class="flex my-auto" v-if="data.Copyright.Right">
                    <div class="mr-2 my-auto flex" :class="item.Class" @click="mainAction(item)" v-for="(item, n) in data.Copyright.Right.Data" :key="n">
                        <b-icon
                            :icon="item.Icon"
                            custom-size="mdi-24px">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>
        <div id="mobile-footer" v-if="data.MobileFooter && windowWidth < 600" :class="data.MobileFooter.Class" :style="data.MobileFooter.Style">
            <div v-ripple style="width: 100%;" class="block text-center mb-0 py-2" :class="activeCondition(menu.ActiveCondition, menu) ? menu.ActiveClass : ''" @click="mainAction(menu)" v-for="(menu, n) in data.MobileFooter.Menu" :key="n">
                <div class="flex justify-center">
                    <b-icon :icon="menu.Icon" class="self-center" size="is-medium"></b-icon>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Logo: () => import('./components/logo'),
        MenuComponent: () => import('./components/menu')
    },
    computed: {
        data () {
            if (this.company.Footer === undefined) return false
            else return this.company.Footer
        }
    }
}
</script>
